import Vue from "vue";
import { ChipListPlugin } from "@syncfusion/ej2-vue-buttons";
Vue.use(ChipListPlugin);
export default Vue.extend({
    computed: {
        isActive() {
            return this.data.Status === "Active";
        },
        isDisabled() {
            return this.data.Status === "Disabled";
        },
        isLockout() {
            return this.data.Status === "Lockout";
        },
        hasNotConfirmedEmail() {
            return this.data.Status === "E-mail not confirmed";
        }
    }
});
