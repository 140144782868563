import Vue from "vue";
import { DialogUtility } from "@syncfusion/ej2-popups";
import { DialogPlugin } from "@syncfusion/ej2-vue-popups";
export var DialogResult;
(function (DialogResult) {
    DialogResult[DialogResult["Yes"] = 0] = "Yes";
    DialogResult[DialogResult["Ok"] = 0] = "Ok";
    DialogResult[DialogResult["No"] = 1] = "No";
})(DialogResult || (DialogResult = {}));
;
Vue.use(DialogPlugin);
export default Vue.extend({
    methods: {
        showConfirmDialog(options) {
            return new Promise((resolve, reject) => {
                const dialog = DialogUtility.confirm({
                    title: options.title,
                    content: options.content,
                    showCloseIcon: false,
                    closeOnEscape: false,
                    okButton: {
                        text: this.$t('shared.yes'),
                        click: () => {
                            dialog.close();
                            resolve(DialogResult.Yes);
                        }
                    },
                    cancelButton: {
                        text: this.$t('shared.no'),
                        click: () => {
                            dialog.close();
                            resolve(DialogResult.No);
                        }
                    },
                    position: {
                        X: "center",
                        Y: "center"
                    }
                });
            });
        },
        showAlertDialog(options) {
            return new Promise((resolve, reject) => {
                const dialog = DialogUtility.alert({
                    title: options.title,
                    content: options.content,
                    showCloseIcon: false,
                    closeOnEscape: false,
                    okButton: {
                        text: "Ok",
                        click: () => {
                            dialog.close();
                            resolve(DialogResult.Ok);
                        }
                    },
                    position: {
                        X: "center",
                        Y: "center"
                    }
                });
            });
        }
    }
});
