import Vue from "vue";
import { GridPlugin, Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn, DetailRow } from "@syncfusion/ej2-vue-grids";
import { CustomUrlAdaptor } from "@/syncfusion/CustomUrlAdaptor";
import { DataManager } from "@syncfusion/ej2-data";
import TitleMixin from "@/mixins/title";
import GridMixin from "@/mixins/syncfusion/grid";
import SpinnerMixin from "@/mixins/spinner";
import DialogMixin, { DialogResult } from "@/mixins/dialog";
import statusColumn from "@/components/Templates/Users/StatusColumn.vue";
Vue.use(GridPlugin);
export default Vue.extend({
    mixins: [GridMixin, TitleMixin, SpinnerMixin, DialogMixin],
    data() {
        return {
            title: this.$t("users.users"),
            data: new DataManager({
                url: process.env.VUE_APP_API_BASE_URL + "users/grid-data",
                //insertUrl: process.env.VUE_APP_API_BASE_URL + "users/grid-create",
                //updateUrl: process.env.VUE_APP_API_BASE_URL + "users/grid-edit",
                removeUrl: process.env.VUE_APP_API_BASE_URL + "users/grid-delete",
                adaptor: new CustomUrlAdaptor()
            }),
            sortOptions: {
                columns: [{ field: "Email", direction: "Ascending" }]
            },
            filterOptions: { type: "Menu" },
            pageSettings: { pageSize: 10 },
            toolbarOptions: [
                {
                    text: this.$t("shared.add"),
                    tooltipText: this.$t("shared.add"),
                    prefixIcon: "e-add",
                    id: "Custom_Add"
                },
                "Search"
            ],
            searchOptions: {
                fields: ["Email", "FirstName", "LastName"],
                operator: "contains"
            },
            editSettings: {
                allowEditing: false,
                allowAdding: false,
                allowDeleting: true,
                showDeleteConfirmDialog: true,
                mode: "Dialog"
            },
            commands: [
                {
                    type: "Custom_Edit",
                    title: this.$t("shared.edit"),
                    buttonOption: { cssClass: "e-flat e-primary", iconCss: "e-edit e-icons" }
                },
                {
                    type: "SendEmailConfirmation",
                    title: this.$t("users.sendEmailConfirmation.actionTitle"),
                    buttonOption: { cssClass: "e-flat send-email-confirmation-btn e-warning", iconCss: "fas fa-envelope" }
                },
                {
                    type: "Unlock",
                    title: this.$t("users.unlock.actionTitle"),
                    buttonOption: { cssClass: "e-flat unlock-btn e-warning", iconCss: "fas fa-unlock" }
                },
                {
                    type: "SendResetPassword",
                    title: this.$t("users.sendResetPassword.actionTitle"),
                    buttonOption: { cssClass: "e-flat send-reset-password-btn e-warning", iconCss: "fas fa-key" }
                },
                {
                    type: "Delete",
                    buttonOption: { cssClass: "e-flat e-danger", iconCss: "e-delete e-icons" }
                }
            ],
            statusColumnTemplate: function () {
                return {
                    template: statusColumn,
                };
            },
            childGrid: this.$store.getters["account/hasMultipleOffices"] ? {
                queryString: 'UserId',
                columns: [
                    { field: 'OfficeId', headerText: this.$t('shared.id'), width: 0, visible: false },
                    { field: 'OfficeName', headerText: this.$t('offices.office'), width: 300 },
                ],
                load: function () {
                    const grid = this;
                    grid.parentDetails.parentKeyFieldValue = grid.parentDetails.parentRowData['Id'];
                }
            } : undefined
        };
    },
    provide: {
        grid: [Page, Filter, Sort, Toolbar, Search, Edit, CommandColumn, DetailRow]
    },
    methods: {
        toolbarClick(args) {
            if (args.item.id == "Custom_Add") {
                this.$router.push("/administration/users/create");
                return;
            }
        },
        async commandClick(args) {
            const commandType = args.commandColumn.type;
            const data = args.rowData;
            if (commandType === "Custom_Edit") {
                return this.$router.push(`/administration/users/${data.Id}/edit`);
            }
            if (commandType === "SendEmailConfirmation") {
                const result = await this.showConfirmDialog({
                    title: this.$t("users.sendEmailConfirmation.confirmDialogTitle"),
                    content: this.$t("users.sendEmailConfirmation.confirmDialogContent", {
                        firstName: data.FirstName,
                        lastName: data.LastName,
                    })
                });
                if (result === DialogResult.Yes)
                    return this.sendEmailConfirmation(data);
                return Promise.resolve();
            }
            if (commandType === "SendResetPassword") {
                const result = await this.showConfirmDialog({
                    title: this.$t("users.sendResetPassword.confirmDialogTitle"),
                    content: this.$t("users.sendResetPassword.confirmDialogContent", {
                        firstName: data.FirstName,
                        lastName: data.LastName,
                    })
                });
                if (result === DialogResult.Yes)
                    return this.sendResetPassword(data);
                return Promise.resolve();
            }
            if (commandType === "Unlock") {
                const result = await this.showConfirmDialog({
                    title: this.$t("users.unlock.confirmDialogTitle"),
                    content: this.$t("users.unlock.confirmDialogContent", {
                        firstName: data.FirstName,
                        lastName: data.LastName,
                    })
                });
                if (result === DialogResult.Yes)
                    return this.unlockUser(data);
                return Promise.resolve();
            }
            return Promise.reject(`The command type '${commandType}' is not managed.`);
        },
        onDetailDataBound(args) {
            const grid = args.detailElement.querySelector('.e-grid');
            const gridInstance = grid.ej2_instances[0];
            gridInstance.dataSource = args.data.Offices;
        },
        actionComplete(args) {
            if (args.requestType === "delete") {
                this.$toast.showSuccessToast(this.$t("users.delete.toastSuccessTitle"), this.$t("users.delete.toastSuccessContent", {
                    name: args.data[0].Name
                }));
            }
        },
        onBeforeDataBound(args) {
            for (const data of args.result) {
                data.RoleName = this.$t(data.RoleName);
            }
        },
        rowDataBound(args) {
            if (args.data.Status !== "E-mail not confirmed") {
                args.row.querySelector(".send-email-confirmation-btn").style.display = "none";
            }
            if (args.data.Status === "E-mail not confirmed" || args.data.Status === "Disabled")
                args.row.querySelector(".send-reset-password-btn").style.display = "none";
            if (args.data.Status !== "Lockout")
                args.row.querySelector(".unlock-btn").style.display = "none";
        },
        async sendEmailConfirmation(user) {
            this.showSpinner();
            try {
                await this.$store.dispatch("user/sendEmailConfirmation", {
                    id: user.Id
                });
                this.$toast.showSuccessToast(this.$t("users.sendEmailConfirmation.toastSuccessTitle"), this.$t("users.sendEmailConfirmation.toastSuccessContent", {
                    firstName: user.FirstName,
                    lastName: user.LastName,
                }));
            }
            catch (e) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("users.sendEmailConfirmation.toastErrorContent", {
                    error: e[0].message
                }));
            }
            this.hideSpinner();
        },
        async sendResetPassword(user) {
            this.showSpinner();
            try {
                await this.$store.dispatch("user/sendResetPassword", {
                    id: user.Id
                });
                this.$toast.showSuccessToast(this.$t("users.sendResetPassword.toastSuccessTitle"), this.$t("users.sendResetPassword.toastSuccessContent", {
                    firstName: user.FirstName,
                    lastName: user.LastName,
                }));
            }
            catch (e) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("users.sendResetPassword.toastErrorContent", {
                    error: e[0].message
                }));
            }
            this.hideSpinner();
        },
        async unlockUser(user) {
            this.showSpinner();
            try {
                await this.$store.dispatch("user/unlock", {
                    id: user.Id
                });
                this.$toast.showSuccessToast(this.$t("users.unlock.toastSuccessTitle"), this.$t("users.unlock.toastSuccessContent", {
                    firstName: user.FirstName,
                    lastName: user.LastName,
                }));
                this.$refs.usersGrid.refresh();
            }
            catch (e) {
                this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("users.unlock.toastErrorContent", {
                    error: e[0].message
                }));
            }
            this.hideSpinner();
        }
    }
});
